import { lazy } from "react";
import { authRoles } from "../../auth/authRoles";

const Solicitudes = lazy(() => import("./requests/solicitudes.view"));
const SolicitudesDetails = lazy(() =>
  import("./requests/details/requestsDetailsContainer")
);
const HistoryRequestsSheet = lazy(() =>
  import("./requests/details/pages/historyRequestsSheet")
);
const OrdersView = lazy(() => import("./orders/ordersContainer"));
const OrdersDetailView = lazy(() =>
  import("./orders/orderDetail/orderDetailContainer")
);
const InvoicesView = lazy(() => import("./invoices/invoicesContainer"));
const RenovationsView = lazy(() => import("./renovations/renovationContainer"));
const DocumentsView = lazy(() => import("./documents/documentsContainer"));
const DocumentDetailView = lazy(() =>
  import("./documents/documentDetail/DocumentDetailContainer")
);
const DocumentDuplicateView = lazy(() =>
  import("./DocumentDuplicates/documentDuplicateContainer")
);
const SalesView = lazy(() => import("./sales/salesContainer"));
const VisitDetailView = lazy(() =>
  import("./requests/details/pages/visitDetail")
);
const PaymentView = lazy(() => import("./requests/details/pages/payment"));
const DetailsInspectionView = lazy(() =>
  import("./requests/details/pages/detailsInspection")
);
const TicketsView = lazy(() => import("./requests/details/pages/tickets"));
const AllowedUsersView = lazy(() =>
  import("./requests/details/pages/allowedUsers")
);
const EditTickets = lazy(() => import("./requests/details/pages/editTickets"));
const UpdateStatesRequestsView = lazy(() =>
  import("./requests/details/pages/updateStatesRequests")
);
const PastRequestsView = lazy(() =>
  import("./requests/details/pages/pastRequests")
);
const HistoryServicesView = lazy(() =>
  import("./requests/details/pages/historyServices")
);
const HistoryRequestsStageView = lazy(() =>
  import("./requests/details/pages/historyRequestsStage")
);
const HistoryAssignedUsersView = lazy(() =>
  import("./requests/details/pages/historyAssignedUsers")
);
const HistoryDateChangesView = lazy(() =>
  import("./requests/details/pages/historyDateChanges")
);
const HistoryDocumentReturnsView = lazy(() =>
  import("./requests/details/pages/historyDocumentReturns")
);
// const HistoryRequestsReturnsView = lazy(() => import('./requests/details/pages/historyRequestsReturns'));
// const HistoryGenerationLicensesView = lazy(() => import('./requests/details/pages/historyGenerationLicenses'));
const AddTickets = lazy(() => import("./requests/details/pages/addTickets"));
const VehiclesView = lazy(() =>
  import("./requests/details/pages/viewVehicles")
);
const SummarySellersView = lazy(() =>
  import("./requests/details/pages/summarySellers")
);
const ScheduledVisitsView = lazy(() =>
  import("./requests/details/pages/scheduledVisits")
);
// const ClientServicesView = lazy(() => import('./requests/details/pages/clientServices'));
const InspectionSheetView = lazy(() =>
  import("./requests/details/pages/inspectionSheet")
);
const PrintDocumentView = lazy(() =>
  import("./requests/details/pages/printDocument")
);
const CreateBreakdownInvoiceView = lazy(() =>
  import("./requests/details/pages/createBreakdownInvoice")
);
const Chat = lazy(() => import("./requests/details/tabs/chat/AppChat.jsx"));
const BreakdownInvoiceView = lazy(() =>
  import("./requests/details/pages/breakdownInvoice")
);
const InspectionFile = lazy(() =>
  import("./requests/details/pages/inspectionFile")
);
const detailsFileViewCompletePages = lazy(() =>
  import("./requests/details/pages/detailsFileViewCompletePages")
);
const DetailsFormularyByRequiredDocuments = lazy(() =>
  import("./requests/details/pages/detailsFormularyByRequiredDocuments")
);

// Se inicializa la constante de las rutas
const solicitudesRoutes = [
  // {
  //     path: "/chat",
  //     component: Chat,
  //     // auth: authRoles.admin
  // },
  {
    path: "/details_formulary/:id/:view/:object",
    component: DetailsFormularyByRequiredDocuments,
    auth: authRoles.admin,
  },
  {
    path: "/complete_formulary_file/:id/:tab/:requestId/:visitId",
    component: detailsFileViewCompletePages,
    auth: authRoles.admin,
  },
  {
    path: "/inspection_file/:id/:tab/:requestId/:visitId",
    component: InspectionFile,
    auth: authRoles.admin,
  },
  {
    path: "/requests/:customerId?/:agencyId?/:startDate?/:endDate?",
    component: Solicitudes,
    auth: authRoles.admin,
  },
  {
    path: "/requests_details/:id/:tab",
    component: SolicitudesDetails,
    auth: authRoles.admin,
  },
  {
    path: "/history_requests_sheet/:id",
    component: HistoryRequestsSheet,
    auth: authRoles.admin,
  },
  {
    path: "/requests_orders/:customerId?",
    component: OrdersView,
    auth: authRoles.admin,
  },
  {
    path: "/requests_orders_detail/:headOcsId",
    component: OrdersDetailView,
    auth: authRoles.admin,
  },
  {
    path: "/invoices/:customerId?/:invoiceStateId?/:agencyId?",
    component: InvoicesView,
    auth: authRoles.admin,
  },
  {
    path: "/renovations/:customerId?",
    component: RenovationsView,
    auth: authRoles.admin,
  },
  {
    path: "/documents/:customerId?",
    component: DocumentsView,
    auth: authRoles.admin,
  },
  {
    path: "/documents_detail/:id/:type",
    component: DocumentDetailView,
    auth: authRoles.admin,
  },
  {
    path: "/document_duplicates",
    component: DocumentDuplicateView,
    auth: authRoles.admin,
  },
  {
    path: "/sales",
    component: SalesView,
    auth: authRoles.admin,
  },
  {
    path: "/visits_details/:id",
    component: VisitDetailView,
    auth: authRoles.admin,
  },
  {
    path: "/payment/:invoiceId",
    component: PaymentView,
    auth: authRoles.admin,
  },
  {
    path: "/details_inspection/:id",
    component: DetailsInspectionView,
    auth: authRoles.admin,
  },
  {
    path: "/details_tickets/:id",
    component: TicketsView,
    auth: authRoles.admin,
  },
  {
    path: "/allowed_users/:id",
    component: AllowedUsersView,
    auth: authRoles.admin,
  },
  {
    path: "/add_tickets/:id",
    component: AddTickets,
    auth: authRoles.admin,
  },
  {
    path: "/edit_tickets/:id",
    component: EditTickets,
    auth: authRoles.admin,
  },
  {
    path: "/update_stage/:id",
    component: UpdateStatesRequestsView,
    auth: authRoles.admin,
  },
  {
    path: "/past_requests/:id",
    component: PastRequestsView,
    auth: authRoles.admin,
  },
  {
    path: "/history_services/:id",
    component: HistoryServicesView,
    auth: authRoles.admin,
  },
  {
    path: "/history_requests_stage/:id",
    component: HistoryRequestsStageView,
    auth: authRoles.admin,
  },
  {
    path: "/history_assigned_users/:id",
    component: HistoryAssignedUsersView,
    auth: authRoles.admin,
  },
  {
    path: "/history_date_changes/:id",
    component: HistoryDateChangesView,
    auth: authRoles.admin,
  },
  {
    path: "/history_document_returns/:id",
    component: HistoryDocumentReturnsView,
    auth: authRoles.admin,
  },
  {
    path: "/vehicles_view/:id",
    component: VehiclesView,
    auth: authRoles.admin,
  },
  {
    path: "/summary_sellers/:id",
    component: SummarySellersView,
    auth: authRoles.admin,
  },
  {
    path: "/scheduled_visits/:id",
    component: ScheduledVisitsView,
    auth: authRoles.admin,
  },
  {
    path: "/visits_inspection_sheet/:id",
    component: InspectionSheetView,
    auth: authRoles.admin,
  },
  // {
  //     path: "/history_requests_returns/:id",
  //     component: HistoryRequestsReturnsView,
  //     auth: authRoles.admin
  // },
  // {
  //     path: "/history_generation_licenses/:id",
  //     component: HistoryGenerationLicensesView,
  //     auth: authRoles.admin
  // },
  {
    path: "/preview_expedite_license/:requestId/:certifiedId",
    component: PrintDocumentView,
    auth: authRoles.admin,
  },
  {
    path: "/breakdown_invoice/:requestId",
    component: CreateBreakdownInvoiceView,
    auth: authRoles.admin,
  },
  {
    path: "/detail_breakdown_invoice/:requestId/:invoiceId",
    component: BreakdownInvoiceView,
    auth: authRoles.admin,
  },
];

export default solicitudesRoutes;
