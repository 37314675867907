import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from './app/translations/es/global.json';
import global_en from './app/translations/en/global.json';
import Home_es from './app/translations/es/Home.json';
import Home_en from './app/translations/en/Home.json';
import Usuarios_es from './app/translations/es/Usuarios.json';
import Usuarios_en from './app/translations/en/Usuarios.json';
import Clientes_es from './app/translations/es/Clientes.json';
import Clientes_en from './app/translations/en/Clientes.json';
import Mantenedores_es from './app/translations/es/Mantenedores.json';
import Mantenedores_en from './app/translations/en/Mantenedores.json';
import Servicios_es from './app/translations/es/Servicios.json';
import Servicios_en from './app/translations/en/Servicios.json';
import RolesPerfiles_es from './app/translations/es/RolesPerfiles.json';
import RolesPerfiles_en from './app/translations/en/RolesPerfiles.json';
import GestionClientes_es from './app/translations/es/GestionClientes.json';
import GestionClientes_en from './app/translations/en/GestionClientes.json';
import DocumentosServicios_es from './app/translations/es/DocumentosServicios.json';
import DocumentosServicios_en from './app/translations/en/DocumentosServicios.json';
import Agencias_en from './app/translations/en/Agencies.json';
import Agencias_es from './app/translations/es/Agencies.json';
import Tools_en from './app/translations/en/Tools.json';
import Tools_es from './app/translations/es/Tools.json';
import GestionClientesEmpresas_en from './app/translations/en/GestionClientesEmpresas.json';
import GestionClientesEmpresas_es from './app/translations/es/GestionClientesEmpresas.json';
import ModulosAgencias_en from './app/translations/en/ModulosAgencias.json';
import ModulosAgencias_es from './app/translations/es/ModulosAgencias.json';
import Requests_en from './app/translations/en/Requests.json';
import Requests_es from './app/translations/es/Requests.json';
import Renovations_en from './app/translations/en/Renovations.json';
import Renovations_es from './app/translations/es/Renovations.json';
import Complaints_en from './app/translations/en/Complaints.json';
import Complaints_es from './app/translations/es/Complaints.json';
import Invoices_en from './app/translations/en/Invoices.json';
import Invoices_es from './app/translations/es/Invoices.json';
import Orders_en from './app/translations/en/Orders.json';
import Orders_es from './app/translations/es/Orders.json';
import Logs_es from './app/translations/es/Logs.json';
import Logs_en from './app/translations/en/Logs.json';
import Reports_es from './app/translations/es/Reports.json';
import Reports_en from './app/translations/en/Reports.json';
import Messages_es from './app/translations/es/Messages.json';
import Messages_en from './app/translations/en/Messages.json';
import Shoppings_es from './app/translations/es/Shoppings.json';
import Shoppings_en from './app/translations/en/Shoppings.json';
import Cart_es from './app/translations/es/Cart.json';
import Cart_en from './app/translations/en/Cart.json';
import Visits_es from './app/translations/es/Visits.json';
import Visits_en from './app/translations/en/Visits.json';
import Transactions_es from './app/translations/es/Transactions.json';
import Transactions_en from './app/translations/en/Transactions.json';
import { instanceClients } from "app/axios/instanceClients";

// Inicializa i18next
i18next.init({
    interpolation: { escapeValue: false },
    lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : 'es',
    resources: {
        es: {
            global: global_es,
            Home: Home_es,
            Usuarios: Usuarios_es,
            Clientes: Clientes_es,
            Mantenedores: Mantenedores_es,
            Servicios: Servicios_es,
            RolesPerfiles: RolesPerfiles_es,
            GestionClientes: GestionClientes_es,
            DocumentosServicios: DocumentosServicios_es,
            Agencias: Agencias_es,
            Tools: Tools_es,
            GestionClientesEmpresas: GestionClientesEmpresas_es,
            ModulosAgencias: ModulosAgencias_es,
            Requests: Requests_es,
            Renovations: Renovations_es,
            Complaints: Complaints_es,
            Invoices: Invoices_es,
            Orders: Orders_es,
            Logs: Logs_es,
            Reports: Reports_es,
            Messages: Messages_es,
            Shoppings: Shoppings_es,
            Cart: Cart_es,
            Visits: Visits_es,
            Transactions: Transactions_es
        },
        en: {
            global: global_en,
            Home: Home_en,
            Usuarios: Usuarios_en,
            Clientes: Clientes_en,
            Mantenedores: Mantenedores_en,
            Servicios: Servicios_en,
            RolesPerfiles: RolesPerfiles_en,
            GestionClientes: GestionClientes_en,
            DocumentosServicios: DocumentosServicios_en,
            Agencias: Agencias_en,
            Tools: Tools_en,
            GestionClientesEmpresas: GestionClientesEmpresas_en,
            ModulosAgencias: ModulosAgencias_en,
            Requests: Requests_en,
            Renovations: Renovations_en,
            Complaints: Complaints_en,
            Invoices: Invoices_en,
            Orders: Orders_en,
            Logs: Logs_en,
            Reports: Reports_en,
            Messages: Messages_en,
            Shoppings: Shoppings_en,
            Cart: Cart_en,
            Visits: Visits_en,
            Transactions: Transactions_en
        }
    },
    defaultNS: 'global',
});

// Función para inicializar Crazy Egg
const initCrazyEgg = async () => {

    // Intenta cargar el script de Crazy Egg
    try {

        // Se consume la API
        const response = await instanceClients.get("/general_system_parameters");

        // Ajusta esto según la estructura de tu respuesta
        const scriptArray = ["servicio_crazy_egg", "servicio_google_analytics"];

        // Maneja la respuesta
        if (response.status === 200) {

            // Ajusta esto según la estructura de tu respuesta
            const scriptTag = response?.data?.data?.filter((r) => scriptArray.includes(r?.attributes?.name));

            // Verifica si se pudo extraer la URL del script
            if (scriptTag.length > 0) {

                // Itera sobre los scripts
                scriptTag.forEach((element) => {

                    // Se valida cuando el servicio es servicio_crazy_egg
                    if (element?.attributes?.name == "servicio_crazy_egg") {

                        // Usa una expresión regular para extraer la URL del src
                        const scriptUrlMatch = element?.attributes?.value.match(/src="([^"]+)"/);
                        const scriptUrl = scriptUrlMatch ? scriptUrlMatch[1] : null;

                        // Inserta el script de Crazy Egg en el head
                        const script = document.createElement("script");

                        // Asigna el tipo del script
                        script.type = "text/javascript";

                        // Asigna el src del script
                        script.src = scriptUrl;

                        // Asigna el atributo async
                        script.async = true;

                        // Agrega el script al head
                        document.head.appendChild(script);
                    } else if (element?.attributes?.name == "servicio_google_analytics") {

                        // Extrae solo el contenido JavaScript
                        const scriptContent = element?.attributes?.value?.replace(/<script[^>]*>|<\/script>/gi, '');

                        // Inserta el script de Crazy Egg en el head
                        const script = document.createElement("script");

                        // Asigna el tipo del script
                        script.type = "text/javascript";

                        // Asigna el src del script
                        script.text = scriptContent;

                        // Asigna el atributo async
                        script.async = true;

                        // Agrega el script al head
                        document.head.appendChild(script);
                    };
                });
            };
        };
    } catch (error) {
        console.error("Error loading Crazy Egg script:", error);
    };
};

// Llama a la función para inicializar Crazy Egg y luego renderiza la aplicación
initCrazyEgg().then(() => {
    ReactDOM.render(
        <I18nextProvider i18n={i18next}>
            <App />
        </I18nextProvider>,
        document.getElementById("root")
    );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();