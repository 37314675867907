import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

// Mantenedor Generador de formularios
const FormBuilderContainer = lazy(() =>
  import("./formBuilder/formBuilderContainer")
);
const FormBuilderDetailsFileContainer = lazy(() =>
  import("./formBuilder/pages/detailsFileView")
);

const TempBuilderContainer = lazy(() =>
  import("./tempBuilder/tempBuilderContainer")
);
const TempBuilderContainerAdd = lazy(() =>
  import("./tempBuilder/pages/CreateTempBuilder")
);
const TempBuilderContainerEdit = lazy(() =>
  import("./tempBuilder/pages/EditTempBuilder")
);
const TempBuilderContainerDetails = lazy(() =>
  import("./tempBuilder/pages/DetailsTempBuilder")
);
const LicenseTypes = lazy(() => import("./licenseTypes/licenseTypeContainer"));
const LicenseComponents = lazy(() =>
  import("./LicenseComponents/licenseComponentsContainer")
);
const LicenseComponentsEdit = lazy(() =>
  import("./LicenseComponents/modals/EditLicenseComponentsModal")
);
const LicenseTemplates = lazy(() =>
  import("./licenseTemplates/licenseTemplatesContainer")
);
const LicenseTemplatesEdit = lazy(() =>
  import("./licenseTemplates/modals/EditLicenseTemplatesModal")
);
const LicenseImageAssets = lazy(() =>
  import("./licenseImageAssets/licenseImageAssetsContainer")
);
// Tipos de hoja de inspección
const InspectionTypesContainer = lazy(() =>
  import("./inspectionsTypes/inspectionTypeContainer")
);
const InspectionTypesContainerDetails = lazy(() =>
  import("./inspectionsTypes/pages/detailsFileView")
);

const Holidays = lazy(() => import("./holidays/holidaysContainer"));
const Sites = lazy(() => import("./sites/SitesContainer"));
const UserSites = lazy(() => import("./userSites/UserSitesContainer"));
const Countries = lazy(() => import("./countries/countriesContainer"));
const Neighborhood = lazy(() => import("./neighborhood/neighborhoodContainer"));
const City = lazy(() => import("./city/cityContainer"));
const Currencies = lazy(() => import("./currencies/currenciesContainer"));
const Parameters = lazy(() => import("./parameters/parametersContainer"));
const States = lazy(() => import("./states/statesContainer"));
const Roles = lazy(() => import("./roles/PermissionContainer"));
const AppareanceSites = lazy(() =>
  import("./appareanceSites/AppareanceSitesContainer")
);

const TypesResolutionsVisitingStatus = lazy(() =>
  import(
    "./typesResolutionsVisitingStatus/typesResolutionsVisitingStatusContainer"
  )
);
const TypesStatusTypeInvoiceDebtsContainer = lazy(() =>
  import("./typesStatusTypeInvoiceDebts/typesStatusTypeInvoiceDebtsContainer")
);
const PaymentsMethodsContainer = lazy(() =>
  import("./paymentsMethods/paymentsMethodsContainer")
);
const StatesRequestsContainer = lazy(() =>
  import("./statesRequests/statesRequestsContainer")
);
const TypesRadiationMachinesContainer = lazy(() =>
  import("./typesRadiationMachines/typesRadiationMachinesContainer")
);
const MachinesGeneralsRadiationContainer = lazy(() =>
  import("./machinesGeneralsRadiation/machinesGeneralsRadiationContainer")
);
const TypesGamesMachinesContainer = lazy(() =>
  import("./typesGamesMachines/typesRadiationMachinesContainer")
);
const TypesEquipmentWeightsMeasuresContainer = lazy(() =>
  import(
    "./typesEquipmentWeightsMeasures/typesEquipmentWeightsMeasuresContainer"
  )
);
const TypesVehiclesContainer = lazy(() =>
  import("./typesVehicles/typesVehiclesContainer")
);
const ManageInfractionsContainer = lazy(() =>
  import("./manageInfractions/manageInfractionsContainer")
);
const CategoryInfractionsContainer = lazy(() =>
  import("./categoryInfractions/categoryInfractionsContainer")
);
const ReasonsStatusRequestContainer = lazy(() =>
  import("./reasonsStatusRequest/reasonsStatusRequestContainer")
);
const DocumentsAssociatedRequestStatementsContainer = lazy(() =>
  import(
    "./documentsAssociatedRequestStatements/documentsAssociatedRequestStatementsContainer"
  )
);
const CategoryDocumentsContainer = lazy(() =>
  import("./categoryDocuments/categoryDocumentsContainer")
);
const CategoryCauseNatureClaimsContainer = lazy(() =>
  import("./categoryCauseNatureClaims/categoryCauseNatureClaimsContainer")
);

const OnlyReadContainer = lazy(() => import("./onlyRead/onlyReadContainer"));

const maintainersRoutes = [
  {
    path: "/maintainers/form_builder",
    component: FormBuilderContainer,
    auth: authRoles.admin,
  },
  {
    path: "/form_builder/details/:id",
    component: FormBuilderDetailsFileContainer,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/temp_builder",
    component: TempBuilderContainer,
    auth: authRoles.admin,
  },
  {
    path: "/add_temp_builder",
    component: TempBuilderContainerAdd,
    auth: authRoles.admin,
  },
  {
    path: "/edit_temp_builder/:id",
    component: TempBuilderContainerEdit,
    auth: authRoles.admin,
  },
  {
    path: "/details_temp_builder/:id",
    component: TempBuilderContainerDetails,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/license_types",
    component: LicenseTypes,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/license_components",
    component: LicenseComponents,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers_license_components/:id",
    component: LicenseComponentsEdit,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/license_images",
    component: LicenseImageAssets,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/license_templates",
    component: LicenseTemplates,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers_license_templates/:id",
    component: LicenseTemplatesEdit,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/inspection_types",
    component: InspectionTypesContainer,
    auth: authRoles.admin,
  },
  {
    path: "/inspection_types/details/:id",
    component: InspectionTypesContainerDetails,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/holidays",
    component: Holidays,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/sites",
    component: Sites,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/userSites",
    component: UserSites,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/countries",
    component: Countries,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/city",
    component: City,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/neighborhood",
    component: Neighborhood,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/currencies",
    component: Currencies,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/parameters",
    component: Parameters,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/states",
    component: States,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/roles",
    component: Roles,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/types_resolutions_visiting_status",
    component: TypesResolutionsVisitingStatus,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/types_status_type_invoice_debts",
    component: TypesStatusTypeInvoiceDebtsContainer,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/payments_methods",
    component: PaymentsMethodsContainer,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/states_requests",
    component: StatesRequestsContainer,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/types_radiation_machines",
    component: TypesRadiationMachinesContainer,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/machines_generals_radiation",
    component: MachinesGeneralsRadiationContainer,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/types_games_machines",
    component: TypesGamesMachinesContainer,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/types_equipment_weights_measures",
    component: TypesEquipmentWeightsMeasuresContainer,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/types_vehicles",
    component: TypesVehiclesContainer,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/manage_infractions",
    component: ManageInfractionsContainer,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/category_infractions",
    component: CategoryInfractionsContainer,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/reasons_status_requests",
    component: ReasonsStatusRequestContainer,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/documents_associated_request_statements",
    component: DocumentsAssociatedRequestStatementsContainer,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/category_documents",
    component: CategoryDocumentsContainer,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/category_cause_nature_claims",
    component: CategoryCauseNatureClaimsContainer,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/appareance_sites",
    component: AppareanceSites,
    auth: authRoles.admin,
  },
  {
    path: "/maintainers/only_lecture_capital_letter",
    component: OnlyReadContainer,
    auth: authRoles.admin,
  },
];

export default maintainersRoutes;
