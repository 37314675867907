import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

// Clientes
const clientsContainers = lazy(() => import('./clients/clientsContainer'));

// Empresas
const companyContainer = lazy(() => import('./company/companyContainer'));

// Mis Clientes especiales
const specialClientsContainers = lazy(() => import('./specialClients/specialClientsContainer'));

const manageClientsRoutes = [
    // Agregar Clientes
    {
        path: "/manageClients/clients",
        component: clientsContainers,
        auth: authRoles.admin
    },

    // Mis Clientes especiales
    {
        path: "/manageClients/special",
        component: specialClientsContainers,
        auth: authRoles.admin
    },

    // Empresas
    {
        path: "/manageClients/company",
        component: companyContainer,
        auth: authRoles.admin
    },
]

export default manageClientsRoutes;